<template>
    <div class="banners">
        <div v-if="this.banners && this.banners.length" class="bannersContent">
            <VueSlickCarousel
                :arrows="false"
                :dots="false"
                :autoplay="true"
                :autoplaySpeed="15000"
                :adaptiveHeight="true"
            >
                <div
                    v-for="(banner, index) in this.banners"
                    :key="banner._id"
                    class="dashboardBanner"
                    @click="bannerClick(index)"
                >
                    <img
                        v-if="banner.format === 'image'"
                        :src="$oldClientTestUrl.slice(0, -1) + banner.image"
                        :alt="banner.imageAlt || ''"
                    />
                    <div
                        v-else-if="banner.format === 'text'"
                        class="dashboardBannerDescription"
                    >
                        <p class="colorMirage dashboardBannerDescription__title">{{ banner.title }}</p>
                        <p class="colorGray dashboardBannerDescription__text">{{ banner.text }}</p>
                        <button class="btn-text dashboardBannerDescription__btn">подробнее</button>
                    </div>
                </div>
            </VueSlickCarousel>
        </div>
        <div v-else class="bannersEmpty">
            <p class="text2 alignCenter colorGullGray bannersEmpty__text">
                Здесь будут появляться специальные предложения для Вас
            </p>
        </div>

        <Confirmation
            v-if="confirmation.isActive"
            :title="confirmation.title"
            :onClose="() => onCloseBannerConfirmation()"
            :onConfirm="() => onConfirmBannerConfirmation()"
        />
    </div>
</template>

<script>
    import Confirmation from "@/views/partials/Confirmation/Confirmation";

    import VueSlickCarousel from 'vue-slick-carousel'
    import 'vue-slick-carousel/dist/vue-slick-carousel.css'
    import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

    export default {
        name: "Banners",

        components: {
            Confirmation,
            VueSlickCarousel,
        },

        computed: {
            banners: function() {
                return (
                    (this.$store.getters.getMember && this.$store.getters.getMember.data && this.$store.getters.getMember.data.middleBanners && this.$store.getters.getMember.data.middleBanners.banners) || []
                );
            },
        },

        data() {
            return {
                confirmation: {
                    isActive: false,
                    title: "",
                },

                bannerData: {
                    bannerID  : "",
                    bannerType: ""
                },
            };
        },

        methods: {
            async onCloseBannerConfirmation() {
                this.confirmation = {
                    isActive: false,
                    title: ""
                }
            },

            async onConfirmBannerConfirmation() {
                const response = await this.axios.post("/banners/request", this.bannerData);

                if (response && response.data && response.data.result) {
                    await this.$store.dispatch("setAlert", {
                        alert: {
                            isActive: true,
                            type: "success",
                            text: "Заявка успешно отправлена",
                        },
                    });
                } else {
                    await this.$store.dispatch("setAlert", {
                        alert: {
                            isActive: true,
                            type: "error",
                            text: "Проблемы при отправке заявки",
                        },
                    });
                }

                this.bannerData = {
                    bannerID  : "",
                    bannerType: ""
                };

                this.confirmation = {
                    isActive: false,
                    title: ""
                }
            },

            async bannerClick(bannerIndex) {
                const banner = this.banners[bannerIndex];

                this.bannerData = {
                    bannerID  : banner.bannerID,
                    bannerType: banner.type
                };

                await this.axios.post("/banners/click", this.bannerData);

                if (banner.type === 'information') {
                    window.open(banner.link, '_blank');
                } else if (banner.type === 'raising') {
                    this.confirmation = {
                        isActive: true,
                        title: "Вы действительно хотите отправить заявку на переход к пакету выше?",
                    }
                } else if (banner.type === 'information_zoho') {
                    this.confirmation = {
                        isActive: true,
                        title: "Вы действительно хотите отправить заявку?",
                    }
                }
            }
        },
    }
</script>

<style scoped lang="scss">
    @import "./Banners.scss";
</style>